import {WorkRepository} from '@domain/models/Work/WorkRepository';
import {WorkCommentary} from '@domain/models/Work/workCommentary';
import {WorkRecordHist} from '@domain/models/Work/workRecordHist';
import {WorkLink} from '@domain/models/Work/workLinks';

export class Work {
  id: number;
  hfn: string;
  language: string;
  parentHfn: string;
  title: string;
  altTitle: string[];
  authority: string;
  origPlaces: OrigPlace[];
  languages: WorkLanguage[];
  authors: WorkAuthor[];
  objectIdentifiers: WorkObjectIdentifier[];
  origDate: string;
  origDateNote: string;
  origDateFrom: string;
  origDateTo: string;
  allYear: boolean;
  briefDescription: string;
  incipit: string;
  explicit: string;
  repositories: WorkRepository[];
  edition: WorkEdition[];
  repertorium: WorkReportorium[];
  literature: WorkLiterature[];
  commentary: WorkCommentary;
  recordHists: WorkRecordHist;
  externalLinks: WorkLink[];
  lastUpdate: string;
  messages: [];
  state: number;
  parent: Work;
  ownerId: string;
  askPublishDateTime: string;


  constructor(init?: Partial<any>) {
    Object.assign(this, init);

    if (init?.workUsers) {
      this.ownerId = init.workUsers[0].userId;
    }
  }

  stateText() {

    switch (this.state) {
      case WorkState.Concept:
        return $localize`:@@Concept.state.concept:Concept`;

      case WorkState.WaitForPublish:
        return $localize`:@@Concept.state.waiting:Waiting for publish`;

      case WorkState.Denied:
        return $localize`:@@Concept.state.Denied:Denied`;

    }

  }
}

export class WorkEdition {
  shortName: string;
  order: number;

}

export class WorkReportorium {
  shortName: string;
  order: number;

}

export class WorkLiterature {
  shortName: string;
  order: number;

}


export class WorkLanguage {

}

export class WorkObjectIdentifier {

}

export class WorkAuthor {
  id: number;
  hfa: string;
  name: string;
  note: string;


  constructor(init?: Partial<WorkAuthor> ) {
    Object.assign(this, init);
  }
}

export class OrigPlace {
  text: string;
  placeId: string;
}


export enum WorkState {
  Concept,
  WaitForPublish,
  Denied
}


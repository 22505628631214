import {AuthorCommentary} from '@domain/models/author/authorCommentary';
import {AuthorRecordHist} from '@domain/models/author/authorRecordHist';
import {Link} from '@domain/models/common/link';

export class Author {

  id: number;
  hfa: string;
  language: string;
  name: string;
  altNames: string[];
  birth: string;
  birthFrom: string;
  birthTo: string;
  birthAllYear: boolean;
  birthPlace: AuthorPlace;
  death: string;
  deathFrom: string;
  deathTo: string;
  deathAllYear: boolean;
  deathPlace: AuthorPlace;
  resume: string;
  biographicalRegisters: AuthorBiographicalRegister[];
  catalogWrit: AuthorCatalogWrit[];
  literature: AuthorLiterature[];
  commentary: AuthorCommentary;
  recordHistory: AuthorRecordHist;
  externalLinks: Link[];
  state: AuthorConceptState;
  ownerId: string;
  askPublishDateTime: string;

  constructor(init: Partial<any>) {
    Object.assign(this, init);


    if (init?.users) {
      this.ownerId = init.users[0].userId;
    }

    this.commentary = init.commentary ? new AuthorCommentary(init.commentary) : null;

  }

  stateText() {
    switch (this.state) {
      case AuthorConceptState.concept:
        return $localize`:@@Concept.state.concept:Concept`;

      case AuthorConceptState.waitForPublish:
        return $localize`:@@Concept.state.waiting:Waiting for publish`;

      case AuthorConceptState.denied:
        return $localize`:@@Concept.state.Denied:Denied`;

    }

  }

}


export class AuthorBiographicalRegister {
  shortName: string;
  text: string;
}

export class AuthorCatalogWrit {
  shortName: string;
  text: string;
}

export class AuthorLiterature {
  shortName: string;
  text: string;
}

export class AuthorPlace {
  text: string;
  placeId: string;
}

export enum AuthorConceptState {
  concept,
  waitForPublish,
  denied
}

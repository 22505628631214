<div id="page" class="h-100">
  <div class="border p-4" style="background-color: white; display: inline-block; max-width: 500px">
    <div id="header" class="px-2 py-3 ta-c white text-center">
      <h1 class="mb-0 h3 md-h2 ml-2 md-ml-3" i18n="@@AppName">HyperFontes</h1>
    </div>

    <div class="dropdown-divider m-0"></div>

    <div class="p-4 d-flex justify-content-center flex-column text-center">
      <div class="d-inline-block h1">
        <div class="d-inline-block" i18n="@@ErrorTitle">
          Ohh... Error: {{code}}!
        </div>
      </div>
      <p class="" i18n="@@ErrorMessage">Sorry. <br> Something went wrong. Please try back later or contact the
        administrator.</p>

      <div class="mt-5">
        <a i18n="@@back" [href]="url">Back</a>

      </div>
    </div>
  </div>

</div>

import {Descendant, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';
import {SelectedItem} from '@domain/models/common/selectedItem';

export class BiblElement extends BaseElement {
  type: 'bibl';
  short: string;
  error: boolean;
  children: Descendant[];

  static wrap(editor, item: SelectedItem, error: boolean = false) {
    this.unwrap(editor, 'bibl');

    const short = item.id ?? item.value

    const biblElement: BiblElement = {
      type: 'bibl',
      short: short,
      error: item.id == undefined,
      children: [{text: short}],
    };


    Transforms.insertNodes(editor, biblElement);
    Transforms.collapse(editor, {edge: 'end'});

  }
}

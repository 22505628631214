import {Work} from '@domain/models/Work/Work';
import {Author} from '@domain/models/author/author';

export class Bibliography {
  id: number;
  shortName: string;
  longName: string;
  link: string;
  sourcesCms: string;
  biblioLink: string;
  googleDocs: boolean;

  works: Work[] = [];
  authors: Author[] = [];
}

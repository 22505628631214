import {Descendant, Range, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';

export class LinkElement extends BaseElement {
  type: 'link';
  url: string;
  children: Descendant[];


  static wrapLink(editor, url: string) {

    this.unwrap(editor, 'link');


    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
      type: 'link',
      url,
      children: isCollapsed ? [{text: url}] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, {split: true});
      Transforms.collapse(editor, {edge: 'end'});
    }
  }

}

<div #preview>

</div>

<ng-template #expand>
  <div class="expandButton" #button>
    <button mat-icon-button (click)="onClickExpand(button)">
      <mat-icon> expand_less</mat-icon>
    </button>
  </div>

</ng-template>

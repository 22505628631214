export class CookieAgreement {

  all = false;
  required = true;
  marketing = false;

  constructor(all = false) {
    this.all = all;
  }

}

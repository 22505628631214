import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {LoadingService} from '../_services/loading.service';
import {RootServiceLocator} from '@domain/services/rootServiceLocator';
import {SnackBarService} from '@domain/services/snack-bar.service';

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    ngUnsubscribe = new Subject<void>();

    protected snackBarService: SnackBarService;
    protected loading: LoadingService;


    protected constructor() {
        this.snackBarService = RootServiceLocator.injector.get(SnackBarService);
        this.loading = RootServiceLocator.injector.get(LoadingService);

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


}

export class Dashboard {
  works: number;
  workConcepts: number;
  authors: number;
  authorConcepts: number;
  places: number;
  persons: number;
  bibliographies: number;

  users: number;

}

import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {

    this.title = data.title;
    this.message = data.message;

  }

  ngOnInit(): void {


  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthorizeService} from '../_services/authorize.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authService: AuthorizeService
    ) {
    }

  canActivate(_next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.isAuthenticated()
      .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state.url)));
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated()
      .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, this.router.url)));
  }


  private handleAuthorization(isAuthenticated: boolean, url: string) {
    if (!isAuthenticated) {
        this.router.navigate(['/authentication/login'], {
            queryParams: {
                returnUrl: url
            }
        });
    }
  }

}

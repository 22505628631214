<div mat-dialog-title class="mb-2">{{title}}</div>


<div mat-dialog-content class="mb-3">
  <div class="d-flex flex-column" >
    {{message}}
  </div>
</div>


<div mat-dialog-actions class="justify-content-end">
  <button mat-button (click)="dialogRef.close(false)" i18n="@@Cancel">Cancel</button>
  <button mat-raised-button color="warn" (click)="dialogRef.close(true)" i18n="@@Ok">Ok</button>
</div>

import {Place} from '@domain/models/place/Place';

export class AuthorHeader {
  altNames: string[];
  birth: string;
  birthFrom: string;
  birthPlace: Place;
  birthTo: string;
  death: '';
  deathFrom: string;
  dateCommentary: '';
  deathPlace: Place;
  deathTo: string;
  headerNote: string;
  hfa: string;
  language: string;
  name: string;
  resume: string;

  constructor(init?: Partial<AuthorHeader>) {
    Object.assign(this, init);

    if (init?.birthPlace) {
      this.birthPlace = new Place(init.birthPlace);
    }

    if (init?.deathPlace) {
      this.deathPlace = new Place(init.deathPlace);
    }
  }
}

export class AuthorCommentary {
  biography: string;
  commentaryNote: string;
  literaryActivity: string;
  researchHistory: string;

  constructor(init?: Partial<AuthorCommentary>) {
    Object.assign(this, init);
  }
}

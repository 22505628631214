<div id="cookieConsent" class="d-flex flex-column flex-lg-row p-3 ">

  <div class="flex-fill m-auto" i18n="@@CookiesMessage">
    By clicking “Allow All”, you agree to the storing of cookies on your device to enhance site navigation.
  </div>

  <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
    <button mat-button style="color: #6CC04A;" class="mr-2 d-inline-block" (click)="Setting()"
            i18n="@@CookiesSetting">Settings cookies
    </button>
    <button mat-raised-button class="d-inline-block" style="background-color: #6CC04A;color: white;" (click)="Agree()"
            i18n="@@CookiesAgree">
      Allow all
      cookies
    </button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      switch (err.status) {
        case 401:
            this.router.navigate(['/authentication/sign-in'], {
                queryParams: {
                    returnUrl: this.router.url
                }
            });
          break;

          // case 404:
          //   window.location.href = `/notFound`;
          //   break;
          case 500:
              window.location.href = `/error?code=${err.status}&location=${location.href}`;
              break;
      }

        return throwError(err);
    }));
  }
}

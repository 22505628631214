export class Role {


  private constructor(value: RoleType) {
    this.value = value;
  }

  value: RoleType;

  static fromInt(value): Role {
    switch (value) {
      case 0:
        return new Role(RoleType.Admin);
      case 1:
        return new Role(RoleType.Editor);
      case 2:
        return new Role(RoleType.User);
      default:
        throw Error('Not allow role');
    }
  }

  static fromString(role: string): Role {
    switch (role) {
        case 'Admin':
            return new Role(RoleType.Admin);
        case 'Editor':
            return new Role(RoleType.Editor);
        case 'User':
            return new Role(RoleType.User);
        default:
            throw new Error(`Unsupported role (multiple roles don’t work): ${role}`);
    }
  }
}


export enum RoleType {
  Admin = 1,
  Editor = 2,
  User = 3,
}


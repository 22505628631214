export class User {
  id: string;
  email: string;
  name: string;
  lastName: string;
  userRoles: UserRole[];
}

export class UserRole {
  role: Role;
}

export class Role {
  name: string;
}

export class AuthorBibliography {
  biblNote: string;
  biographicalRegisters: AuthorBibliographyItem[];
  catalogWrits: AuthorBibliographyItem[];
  literatures: AuthorBibliographyItem[];

  constructor(init?: Partial<AuthorBibliography>) {
    Object.assign(this, init);

    if (init.biographicalRegisters) {
      this.biographicalRegisters = init.biographicalRegisters.map(value => new AuthorBibliographyItem(value));
    }

    if (init.catalogWrits) {
      this.catalogWrits = init.catalogWrits.map(value => new AuthorBibliographyItem(value));
    }

    if (init.literatures) {
      this.literatures = init.literatures.map(value => new AuthorBibliographyItem(value));
    }

  }
}

export class AuthorBibliographyItem {
  shortName: string;
  text: string;


  constructor(init?: Partial<AuthorBibliographyItem>) {
    Object.assign(this, init);

  }
}

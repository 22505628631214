<div id="page" class="h-100">
  <div class="border p-4" style="background-color: white; display: inline-block; max-width: 500px">
    <div id="header" class="px-2 py-3 ta-c white text-center">
      <h1 class="mb-0 h3 md-h2 ml-2 md-ml-3" i18n="@@AppName">HyperFontes</h1>
    </div>

    <div class="dropdown-divider m-0"></div>

    <div class="p-4 d-flex justify-content-center flex-column text-center">
      <div class="d-inline-block h1">404 -
        <div class="d-inline-block" i18n="NotFoundTitle">
          Not found
        </div>
      </div>
      <p class="" i18n="@@NotFoundMessage">The page you are looking for has either been moved, renamed or is temporarily
        unavailable!</p>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <button mat-raised-button class="" color="primary" [routerLink]="['/']" i18n="GoToHomePage">Go to home page
      </button>
    </div>
  </div>

</div>

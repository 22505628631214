import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from '@shared/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {

  }

  public openError(message: string = $localize`:@@snackbarErrorMessage:Ups something wrong. Please try later.`, title: string = $localize`:@@snackbarErrorTitle:Error`) {
    this.openSnackBar(message, title, 'snackbar-error');
  }

  public openSuccess(message: string, title: string = $localize`:@@snackbarSuccess:Success`) {
    this.openSnackBar(message, title, 'snackbar-success');
  }

  public openInfo(message: string, title: string = $localize`:@@snackbarInfoTitle:Info`) {
    this.openSnackBar(message, title, 'snackbar-info');
  }

  private openSnackBar(message: string, title: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 5000,
        data: {
          title,
          message
        },
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: [className]
      }
    )
    ;
  }

}

import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {CookiesBannerService} from '../../_services/cookies-banner.service';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CookieAgreement} from '@models';

@Component({
  selector: 'app-cookies-setting-dialog',
  templateUrl: './cookies-setting-dialog.component.html',
  styleUrls: ['./cookies-setting-dialog.component.css']
})
export class CookiesSettingDialogComponent implements OnInit, AfterViewInit {

  necessaryMessage = false;
  private cookieAgreement: CookieAgreement = new CookieAgreement();

  constructor(
    private cookiesBannerService: CookiesBannerService,
    public dialogRef: MatDialogRef<CookiesSettingDialogComponent>,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

  agreeAll() {
    this.cookiesBannerService.allow();
    this.dialogRef.close();
  }

  Confirm() {
    this.cookiesBannerService.allow(this.cookieAgreement);
    this.dialogRef.close();
  }

  toggleNecessary() {
    this.necessaryMessage = !this.necessaryMessage;
  }

}

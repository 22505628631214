export class BiblDetail {
  name: String;
  link: string;
  sourcesCms: string;
  biblioLink: string;
}





import {Component, OnInit} from '@angular/core';
import {CookiesBannerService} from '../_services/cookies-banner.service';
import {CookiesSettingDialogComponent} from './cookies-setting-dialog/cookies-setting-dialog.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-cookies-banneer',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.css']
})
export class CookiesBannerComponent implements OnInit {

  constructor(
    private cookiesBannerService: CookiesBannerService,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {

  }

  public Agree() {
    this.cookiesBannerService.allow();
  }

  Setting() {
    const dialogRef = this.dialog.open(CookiesSettingDialogComponent);
    dialogRef.afterClosed().subscribe(

    );
  }
}

export enum ApprovalType {
  work,
  author
}

export const relatedLinkOption = [
  {
    regex: new RegExp(/https:\/\/www\.deutsche-biographie\.de.*/gmi),
    text: 'Deutsche Biographie'
  },

  {
    regex: new RegExp(/https:\/\/.*\.wikipedia\.org.*/gmi),
    text: 'Wikipedie'
  },
  {
    regex: new RegExp(/https:\/\/.*\.nkp\.cz.*/gmi),
    text: 'Databáze národních autorit (NK ČR)'
  },
  {
    regex: new RegExp(/https:\/\/.*biblio\.hiu\.cas\.cz.*/gmi),
    text: 'Bibliografie dějin Českých zemí'
  },
];

export const bibliographyLinks = {
  bibliography: 'https://docs.google.com/spreadsheets/d/1e9VQAgq79eo7KYLAN26DvcjI-gVlGAbtSzq_OjCQOlo',
  books: 'https://docs.google.com/spreadsheets/d/1TlV8W2cb312Tq7yF7LtfauhBta1RPDM4a95egXits4M',
};

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortDirection, SortDirectionType, SortEvent} from '@models';


@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {
  _isActive = false;
  sortDirectionType = SortDirectionType;
  direction: SortDirection = new SortDirection();

  @Input() text = '';
  @Input() name = '';
  @Output('changeSort') sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  constructor() {

  }

  get isActive(): boolean {
    return this._isActive;
  }

  @Input() set isActive(value: boolean) {
    this._isActive = value;
    this.emitSelected();
  }


  ngOnInit(): void {

  }

  changeDirection() {
    if (!this.isActive) {
      this._isActive = true;
    } else {
      this.direction.change();
    }

    this.emitSelected();
  }

  emitSelected() {
    if (this._isActive) {
      const sortEvent = new SortEvent();
      sortEvent.name = this.name;
      sortEvent.direction = this.direction;
      this.sort.emit(sortEvent);
    }
  }
}

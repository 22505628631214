<div class="biblTooltip p-2 pt-3 pb-3 border">

  <ng-container [ngTemplateOutlet]="template"></ng-container>
</div>


<ng-template #bibliography>
  <div>
    {{bibliographyLongName}}
  </div>
</ng-template>


<ng-template #place>
  <div>
    <div class="name">
      {{element.name}}
    </div>
    <div class="mt-2">
      <div *ngFor="let link of element.links">
        <a [href]="link.link">
          {{link.text}}
        </a>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #workLink>
  <div>
    <div class="mt-2">
      <div *ngFor="let link of element.links">
        <a [href]="'works/' + link.link">
          {{link.value}}
        </a>
      </div>

    </div>
  </div>
</ng-template>


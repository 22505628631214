import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorizeService} from '../_services/authorize.service';
import {map} from 'rxjs/operators';
import {Role, RoleType} from '../_models/Role';

@Injectable({
    providedIn: 'root'
})
export class AdminOrEditorGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authService: AuthorizeService
    ) {
    }

  canActivate(_next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.authService.getRole().pipe(map(role => this.handleRole(role, state.url)));
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.getRole().pipe(map(role => this.handleRole(role, this.router.url)));
  }

  private handleRole(role: Role, url: string) {
    if (role.value == RoleType.Admin || role.value == RoleType.Editor) {

      return true;
    }

      this.router.navigate(['/authentication/access-denied'], {
          queryParams: {}
      });
    return false;
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '@shared/shared.module';
import {AuthorizeInterceptor} from './account/_helpers/authorize.interceptor';

import {AuthGuard} from './account/_helpers/auth.guard';
import {AdminOrEditorGuard} from './account/_helpers/admin-or-editor.guard';
import {ErrorInterceptor} from './account/_helpers/error.interceptor';
import {ErrorComponent} from '@shared/error/error.component';
import {NotFoundComponent} from '@shared/not-found/not-found.component';
import {QuillModule} from 'ngx-quill';
import {RootServiceLocator} from '@domain/services/rootServiceLocator';
import {NGX_MAT_DATE_FORMATS, NgxMatDateFormats} from '@angular-material-components/datetime-picker';
import {MAT_DATE_LOCALE} from '@angular/material/core';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY HH:mm'
  },
  display: {
    dateInput: 'DD.MM.YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      {path: 'error', pathMatch: 'full', component: ErrorComponent},
      {path: 'notFound', pathMatch: 'full', component: NotFoundComponent},
      {
        path: 'authentication',
        loadChildren: () => import('./account/authorization.module').then(m => m.AuthorizationModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [AuthGuard, AdminOrEditorGuard],
        canActivate: [AuthGuard, AdminOrEditorGuard],
      },
      {
        path: '',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {path: '**', redirectTo: 'notFound'},
    ], {}),
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'cs_CZ'},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private injector: Injector
  ) {
    RootServiceLocator.injector = this.injector;
  }

}

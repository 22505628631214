import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snacbar-error',
  templateUrl: './snacbark.component.html',
  styleUrls: ['./snacbark.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {
                title: string;
                message: string;
              },
              public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
  }

}

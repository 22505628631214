import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BaseComponent} from '@base';
import {ConnectionPositionPair, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {DetailTooltipComponent} from '@shared/detail-html/detail-tooltip/detail-tooltip.component';
import {Bibliography, Person, Place, WorkLinks} from '@models';

@Component({
  selector: 'app-detail-html',
  templateUrl: './detail-html.component.html',
  styleUrls: ['./detail-html.component.scss']
})
export class DetailHtmlComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('expand', {static: true}) expandButton: TemplateRef<any>;
  @ViewChild('preview', {static: true}) preview: ElementRef<HTMLDivElement>;

  @Input() html: string;

  private overlayRef: OverlayRef;
  private enterInOverlay = false;
  private timeout: any = null;


  constructor(
    private render: Renderer2,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay
  ) {
    super();
  }

  ngOnInit(): void {


  }


  ngOnChanges(changes: SimpleChanges): void {


    if (changes.html.currentValue !== changes.html.previousValue) {

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.preview.nativeElement.children.length; i++) {
        this.render.removeChild(this.preview.nativeElement, this.preview.nativeElement.children[i]);
      }


      if (this.html != null) {
        const element = this.render.createElement('div');
        element.innerHTML = this.html;
        this.render.appendChild(this.preview.nativeElement, element);

        const sections = this.preview.nativeElement.getElementsByClassName('section');

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < sections.length; i++) {
          const section = sections[i];

          const embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.expandButton);
          this.render.appendChild(section, embeddedViewRef.rootNodes[0]);

        }

        // this.removeEmpty();

        this.bibls();
        this.places();
        this.persons();
        this.workLinks();
        this.altNames();
      }

    }
  }

  bibls() {
    const bibls = this.preview.nativeElement.getElementsByTagName('bibl');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < bibls.length; i++) {

      const bibl = bibls[i];
      const shortName = bibl.textContent.trim();
      const link = this.render.createElement('a');
      const encoded = encodeURIComponent(shortName);
      link.href = `/browser/bibliography/${encoded}`;
      link.text = shortName;
      bibl.innerHTML = '';

      const bibliography = new Bibliography();
      bibliography.shortName = shortName;

      link.addEventListener('mouseover', event => {
        this.showToolTip(link, bibliography);
      });

      link.addEventListener('mouseleave', event => {
        this.hideToolTip(500);
      });

      this.render.appendChild(bibl, link);
    }
  }

  places() {
    const places = this.preview.nativeElement.getElementsByTagName('place');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < places.length; i++) {

      const placeElement = places[i];
      const link = this.render.createElement('a');


      const place = new Place();
      place.placeId = placeElement.getAttribute('placeId');
      place.name = placeElement.getAttribute('name');

      if (placeElement.getAttribute('links').trim().length !== 0) {
        place.links = JSON.parse(placeElement.getAttribute('links'));
      }


      link.href = place.placeId ? `/browser/places/${place.placeId}` : `/browser/places/${place.name}`;
      link.text = placeElement.innerHTML;
      placeElement.innerHTML = '';

      link.addEventListener('mouseover', event => {
        this.showToolTip(link, place);
      });
      //
      link.addEventListener('mouseleave', event => {
        this.hideToolTip(500);
      });

      this.render.appendChild(placeElement, link);
    }
  }

  persons() {
    const places = this.preview.nativeElement.getElementsByTagName('person');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < places.length; i++) {

      const personElement = places[i];
      const link = this.render.createElement('a');


      const person = new Person();
      person.personId = personElement.getAttribute('personId');
      person.name = personElement.getAttribute('name');
      if (personElement.getAttribute('links')?.trim().length !== 0) {
        person.links = JSON.parse(personElement.getAttribute('links'));
      }

      link.href = person.personId ? `/browser/persons/${person.personId}` : `/browser/places/${person.name}`;
      link.text = personElement.innerHTML;
      personElement.innerHTML = '';

      link.addEventListener('mouseover', event => {
        this.showToolTip(link, person);
      });
      //
      link.addEventListener('mouseleave', event => {
        this.hideToolTip(500);
      });

      this.render.appendChild(personElement, link);
    }
  }

  workLinks() {
    const workLinkElemente = this.preview.nativeElement.getElementsByTagName('worklink');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < workLinkElemente.length; i++) {

      const workLinkElement = workLinkElemente[i];
      const link = this.render.createElement('a');


      const workLinks = new WorkLinks();

      if (workLinkElement.getAttribute('links').trim().length !== 0) {
        workLinks.links = JSON.parse(workLinkElement.getAttribute('links'));
      }

      link.text = workLinkElement.innerHTML;
      workLinkElement.innerHTML = '';


      link.addEventListener('mouseover', event => {
        this.showToolTip(link, workLinks);
      });

      link.addEventListener('mouseleave', event => {
        this.hideToolTip(500);
      });

      this.render.appendChild(workLinkElement, link);
    }
  }

  altNames() {
    const repositoryaltnames = this.preview.nativeElement.getElementsByTagName('repositoryaltname');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < repositoryaltnames.length; i++) {

      const repositoryAltNameElement = repositoryaltnames[i];

      const link = this.render.createElement('a');

      link.text = repositoryAltNameElement.innerHTML;
      link.href = `/browser/repository/altName/${repositoryAltNameElement.getAttribute('altname')}`;
      repositoryAltNameElement.innerHTML = '';

      this.render.appendChild(repositoryAltNameElement, link);
    }
  }


  showToolTip(origin, input: Bibliography | Place | Person | WorkLinks) {
    if (this.timeout != null) {
      this.hideToolTip();
    }

    const positionStrategyBuilder = this.overlay.position();
    const positionStrategy = positionStrategyBuilder
      .flexibleConnectedTo(origin)
      .withFlexibleDimensions(true)
      .withViewportMargin(10)
      .withGrowAfterOpen(true)
      .withPositions([
        new ConnectionPositionPair(
          {originX: 'start', originY: 'top'},
          {overlayX: 'start', overlayY: 'bottom'},
        ),
        new ConnectionPositionPair(
          {originX: 'start', originY: 'bottom'},
          {overlayX: 'start', overlayY: 'top'},
        ),

      ])
    ;

    const scrollStrategy = this.overlay.scrollStrategies.block();
    this.overlayRef = this.overlay.create(
      {
        positionStrategy,
        scrollStrategy,
        maxWidth: '600px',
      }
    );

    this.overlayRef.hostElement.addEventListener('mouseenter', event => {
      this.enterInOverlay = true;
    });

    this.overlayRef.hostElement.addEventListener('mouseleave', event => {
      this.enterInOverlay = false;
      this.hideToolTip(500);
    });

    const componentPortal = new ComponentPortal(DetailTooltipComponent);

    const componentRef = this.overlayRef.attach(componentPortal);
    componentRef.instance.element = input;
  }

  hideToolTip(timeout = 0) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    if (timeout === 0) {
      this.overlayRef?.detach();
      this.overlayRef = null;
      this.overlayRef?.dispose();
    } else {
      this.timeout = setTimeout(() => {
        if (this.overlayRef?.hasAttached() === true && !this.enterInOverlay) {
          this.overlayRef?.detach();
          this.overlayRef?.dispose();
          this.overlayRef = null;
        }
      }, timeout);
    }


  }


  onClickExpand(event) {
    const parentElement = event.parentElement;
    if (parentElement.classList.contains('sectionCollapse')) {
      this.render.removeClass(parentElement, 'sectionCollapse');
    } else {
      this.render.addClass(parentElement, 'sectionCollapse');
    }
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.overlayRef?.detach();
    this.overlayRef?.dispose();


  }

  private removeEmpty() {
    const rows = this.preview.nativeElement.getElementsByTagName('tr');


    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];

      if (row.children.length === 2 && !this.isNotEmpty(row.children[1])) {
        this.render.removeChild(row.parentElement, row);
      }

    }

  }

  private isNotEmpty(element: Element): boolean {

    let result = false;

    if (element.textContent.trim().length !== 0 && element.children.length === 0) {
      return true;
    } else {

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i];
        result = result || this.isNotEmpty(child);
      }


    }

    return result;
  }


}

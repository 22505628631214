export class WorkLinks {
  links: WorkLink[];
  note: string;


  constructor(init?: Partial<WorkLinks>) {
    Object.assign(this, init);

    if (init?.links) {
      this.links = init.links.map(value => new WorkLink(value));
    }
  }

}

export class WorkLink {
  link: string;
  value: string;

  constructor(init?: Partial<WorkLink>) {
    Object.assign(this, init);
  }
}

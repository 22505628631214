import {Work} from '@models';

export class WorkRepository {
  id: number;
  altName: string;
  altSign: string;
  availability: string;
  extent: string;
  extentLink: string;
  repository: string;
  repositoryShortName: string;
  fond: string;
  sign: string;
  work: Work;
  note: string;
  physicalDescription: string;
  incipit: string;
  explicit: string;

  constructor(data?: Partial<WorkRepository>) {
    Object.assign(this, data);
    if (data?.work) {
      this.work = new Work(data?.work);
    }
  }
}

import {PlaceLink} from './PlaceLink';
import {Work} from '@domain/models/Work/Work';
import {Author} from '@domain/models/author/author';

export class Place {
  id = 0;
  placeId = '';
  name = '';
  links: PlaceLink[] = [];
  works: Work[] = [];
  authors: Author[] = [];
  note = '';

  constructor(init?: Partial<Place>) {
    Object.assign(this, init);

    if (init?.links) {
      this.links = init.links.map(value => new PlaceLink(value));
    }
  }

}

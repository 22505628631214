<div class="sort d-flex flex-row" [ngClass]="{
selected: isActive
}"
     (click)="changeDirection()"
>

  <div class="font-weight-bold d-flex align-items-end">
    {{text}}
  </div>

  <div class="sortDirection pl-2 ">
    <img [ngClass]="{direction: direction.type === sortDirectionType.asc}" src="assets/img/arrow_up.png">
    <img [ngClass]="{direction: direction.type === sortDirectionType.desc}" src="assets/img/arrow_down.png">
  </div>
</div>

export class AuthorExternalLinks {
  links: AuthorExternalLink[];
  note: string;

  constructor(init?: Partial<AuthorExternalLinks>) {
    Object.assign(this, init);

    if (init?.links) {
      this.links = init.links.map(value => new AuthorExternalLink(value));
    }
  }
}


export class AuthorExternalLink {
  link: string;
  value: string;

  constructor(init?: Partial<AuthorExternalLink>) {
    Object.assign(this, init);
  }
}

import {Descendant, Range, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';
import {PlaceLink} from '@domain/models/place/PlaceLink';
import {Place} from '@domain/models/place/Place';

export class PlaceElement extends BaseElement {
  type: 'place';
  placeId: string;
  name: string;
  note: string;
  links: PlaceLink[] = [];
  children: Descendant[];


  static wrap(editor, place: Place) {
    this.unwrap(editor, 'place');


    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const placeElement: PlaceElement = {
      type: 'place',
      placeId: place.placeId,
      name: place.name,
      note: place.note,
      links: place.links,
      children: isCollapsed ? [{text: place.name}] : [],
    };

    Transforms.setSelection(editor, selection);
    if (isCollapsed) {
      Transforms.insertNodes(editor, placeElement);
    } else {
      Transforms.wrapNodes(editor, placeElement, {split: true});
      Transforms.collapse(editor, {edge: 'end'});
    }
  }

}

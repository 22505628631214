import {SelectedItem} from '@domain/models/common/selectedItem';
import {Place} from '@domain/models/place/Place';

export class WorkHeader {
  hfn: string;
  language: string;
  title: string;
  altTitle: string[];
  parent: SelectedItem;
  authors: SelectedItem[];
  objectIdentifiers: SelectedItem[];
  languages: SelectedItem[];
  authority: string;
  origPlaces: Place[];
  origDate: string;
  origDateFrom: string;
  origDateTo: string;
  origDateNote: string;
  briefDescription: string;
  incipit: string;
  explicit: string;
  headerNote: string;

  constructor(init?: Partial<WorkHeader>) {
    Object.assign(this, init);

    if (init?.parent) {
      this.parent = new SelectedItem(init.parent);
    }

    if (init?.authors) {
      this.authors = init.authors.map(value => new SelectedItem(value));
    }

    if (init?.objectIdentifiers) {
      this.objectIdentifiers = init.objectIdentifiers.map(value => new SelectedItem(value));
    }

    if (init?.languages) {
      this.languages = init.languages.map(value => new SelectedItem(value));
    }

    if (init?.origPlaces) {
      this.origPlaces = init.origPlaces.map(value => new Place(value));
    }

  }


}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    public loading: Subject<boolean> = new Subject<boolean>();
    private count = 0;

    constructor() {

  }


  public show(count: number = 0) {
      if (count === 0) {
          this.count = 1;
      } else {
          this.count += count;
      }

    this.loading.next(true);
  }

  public hide() {
    this.count--;

      if (this.count === 0) {
          this.loading.next(false);
      }
  }

  public onDestroy() {
      this.loading.complete();
  }


}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public url: string;
  public code: string;

  constructor(private routeActive: ActivatedRoute,) {
  }


  ngOnInit(): void {
    this.routeActive.queryParams.subscribe(value => {
      this.code = value['code'];
      this.url = value['location'];
    });
  }

}

import {Descendant, Range, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';
import {Person} from '@domain/models/person/person';
import {PersonLink} from '@domain/models/person/personLink';

export class PersonElement extends BaseElement {
  type = 'person';
  personId: string;
  name: string;
  note: string;
  links: PersonLink[] = [];
  children: Descendant[];

  static wrap(editor, person: Person) {
    this.unwrap(editor, 'place');


    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const personElement: PersonElement = {
      type: 'person',
      personId: person.personId,
      name: person.name,
      note: person.note,
      links: person.links,
      children: isCollapsed ? [{text: person.name}] : [],
    };
    Transforms.setSelection(editor, selection);

    if (isCollapsed) {
      Transforms.insertNodes(editor, personElement);
    } else {
      Transforms.wrapNodes(editor, personElement, {split: true});
      Transforms.collapse(editor, {edge: 'end'});
    }
  }

}

export class WorkBibliography {
  edition: WorkBibliographyItem[];
  repertorium: WorkBibliographyItem[];
  literature: WorkBibliographyItem[];
  note: string;

  constructor(init?: Partial<WorkBibliography>) {
    Object.assign(this, init);

    if (init.edition) {
      this.edition = init.edition.map(value => new WorkBibliographyItem(value));
    }

    if (init.repertorium) {
      this.repertorium = init.repertorium.map(value => new WorkBibliographyItem(value));
    }

    if (init.literature) {
      this.literature = init.literature.map(value => new WorkBibliographyItem(value));
    }

  }

}

export class WorkBibliographyItem {
  shortName: string;
  text: string;


  constructor(init?: Partial<WorkBibliographyItem>) {
    Object.assign(this, init);

  }
}

import {Descendant, Range, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';

export class RepositoryAltNameElement extends BaseElement {
  type: 'repositoryAltName';
  altName: string;
  children: Descendant[];

  static wrap(editor, item: string) {
    this.unwrap(editor, 'repositoryAltName');

    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);


    const repositoryAltName: RepositoryAltNameElement = {
      type: 'repositoryAltName',
      altName: item,
      children: isCollapsed ? [{text: item}] : [],
    };
    Transforms.setSelection(editor, selection);

    if (isCollapsed) {
      Transforms.insertNodes(editor, repositoryAltName);
    } else {
      Transforms.wrapNodes(editor, repositoryAltName, {split: true});
      Transforms.collapse(editor, {edge: 'end'});
    }
  }
}

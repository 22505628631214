<div class="font-weight-bold" i18n="@@PrivacyCenter">Privacy Preference Center</div>

<mat-dialog-content>
  <div id="privacyCenter" style="max-width: 575px; color: #696969;">

    <div class="text-justify" style="font-size: 0.8rem;" i18n="@@PrivacyCenterMessage">
      When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies.
      This information might be about you, your preferences or your device and is mostly used to make the site work as
      you expect it to. The information does not usually directly identify you, but it can give you a more personalized
      web experience.

      Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the
      different category headings to find out more and change our default settings. However, blocking some types of
      cookies may impact your experience of the site and the services we are able to offer.

      In some cases, data obtained from cookies is shared with third parties and may be considered a “sale of personal
      information” under the California Consumer Privacy Act (CCPA). You can exercise your right to opt-out of that
      sharing at any time by disabling cookies. <a class="text-nowrap"
                                                   href="https://cookiepedia.co.uk/giving-consent-to-cookies">More
      information</a>
    </div>

    <div class="mt-4 mb-4">
      <button mat-raised-button style="background-color: #6CC04A; color: white;" (click)="agreeAll()" i18n="@@AllowAll">
        Allow all
      </button>
    </div>


    <div>
      <div class="font-weight-bold mb-2" style="font-size: 1rem" i18n="@@ManagementConsent">
        Management of consent preferences
      </div>

      <div>
        <div class="ConsentBorder" (click)="toggleNecessary()">
          <div class="d-flex font-weight-bold p-3">
            <div>
                <mat-icon *ngIf="!necessaryMessage" style="font-size: 20px;" class="d-flex align-items-center icon mr-2 ">
                  add
                </mat-icon>
                <mat-icon *ngIf="necessaryMessage" style="font-size: 20px;" class="d-flex align-items-center icon mr-2 ">
                  remove
                </mat-icon>
            </div>
            <div class="flex-fill m-auto" i18n="@@NecessaryCookies">Absolutely necessary cookies</div>
            <div class="m-auto" style="color: #3860be; font-size: 0.8rem" i18n="@@AlwaysActive">
              Always active
            </div>
          </div>
          <div class="p-3 necessaryMessage"
               [ngClass]="{
               necessaryMessageNone :!necessaryMessage
               }"
               i18n="@@NecessaryMessage">
            These cookies are necessary for the website to function and cannot be switched off in our systems. They are
            usually only set in response to actions made by you which amount to a request for services, such as setting
            your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work. These cookies do not store any
            personally identifiable information.
          </div>
        </div>


      </div>


    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="mb-1" align="end">
  <button mat-raised-button style="background-color: #6CC04A;color: white;" (click)="Confirm()"
          i18n="@@ConfirmMyChoose">Confirm my choose
  </button>
</div>

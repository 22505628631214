export enum SortDirectionType {
  asc,
  desc
}

export class SortDirection {
  type: SortDirectionType = SortDirectionType.asc;

  change() {
    this.type = (this.type + 1) % 2;
  }

  toString() {
    switch (this.type) {
      case SortDirectionType.asc:
        return 'asc';
      case SortDirectionType.desc:
        return 'desc';
    }
  }

}

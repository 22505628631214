import {Work} from '@domain/models/Work/Work';
import {Author} from '@domain/models/author/author';
import {PersonLink} from '@domain/models/person/personLink';

export class Person {
  id = 0;
  personId = '';
  name = '';
  note = '';
  links: PersonLink[] = [];
  works: Work[] = [];
  authors: Author[] = [];
}

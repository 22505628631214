import {SortDirection, SortDirectionType} from '@domain/models/Sort/SortDirection';


export class SortEvent {
  name: string;
  direction: SortDirection;


  constructor();
  constructor(name: string, direction: SortDirectionType);


  public constructor(...arr: any[]) {
    if (arr.length === 2) {
      this.name = arr[0];
      const sortDirection = new SortDirection();
      sortDirection.type = arr[1];
      this.direction = sortDirection;
    }
  }

}

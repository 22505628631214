import {Editor, Element, Path, Transforms} from 'slate';

export class BaseElement {

  static unwrap(editor, type: string) {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && Element.isElement(n) && (n as any).type === type,
    });
  }

  static unwrapAt(editor, at: Path) {
    Transforms.unwrapNodes(editor, {
      at
    });
  }


}

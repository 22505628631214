import {Descendant, Transforms} from 'slate';
import {BaseElement} from '@domain/models/editor/baseElement';
import {WorkLink} from '@domain/models/Work/workLinks';


export class WorkLinkElement extends BaseElement {
  type: 'workLink';
  children: Descendant[];
  links: WorkLink[];

  static wrap(editor, links: WorkLink[]) {
    BaseElement.unwrap(editor, 'workLink');


    const workLinkElement: WorkLinkElement = {
      type: 'workLink',
      links,
      children: [],
    };

    Transforms.wrapNodes(editor, workLinkElement, {split: true});
    Transforms.collapse(editor, {edge: 'end'});

  }
}

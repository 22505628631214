import {Component, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Bibliography, Person, Place, WorkLinks} from '@models';
import {BaseComponent} from '@base';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-detail-tooltip',
  templateUrl: './detail-tooltip.component.html',
  styleUrls: ['./detail-tooltip.component.scss']
})
export class DetailTooltipComponent extends BaseComponent implements OnInit {

  @Input() element: Bibliography | Place | Person | WorkLinks;
  @ViewChild('bibliography', {static: true}) bibliographyTemplate: TemplateRef<any>;
  @ViewChild('place', {static: true}) placeTemplate: TemplateRef<any>;
  @ViewChild('workLink', {static: true}) workLinkTemplate: TemplateRef<any>;

  template: TemplateRef<any> = null;

  bibliographyLongName = '';

  constructor(
    private _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    super();

  }

  ngOnInit(): void {


    switch (true) {
      case this.element instanceof Bibliography: {
        this.template = this.bibliographyTemplate;
        this.loadBibliography();
        break;
      }

      case this.element instanceof Place: {
        this.template = this.placeTemplate;
        break;
      }

      case this.element instanceof Person: {
        this.template = this.placeTemplate;
        break;
      }

      case this.element instanceof WorkLinks: {
        this.template = this.workLinkTemplate;

        break;
      }
    }

  }

  private loadBibliography() {

    const bibliography = this.element as Bibliography;
    const encoded = encodeURIComponent(bibliography.shortName);
    this._httpClient.get<Bibliography>(this.baseUrl + `api/catalog/bibliography/${encoded}`)
      .subscribe(value => {

        this.bibliographyLongName = value.longName;
      }, error => {

        this.bibliographyLongName = $localize`:@@notFound: Not found`;
      });
  }
}

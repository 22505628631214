import {Author} from '@domain/models/author/author';
import {Work} from '@domain/models/Work/Work';

export class SearchResultItem {
  author: Author;
  work: Work;
  expand = false;
  fragments: SearchResultFragment[];


  constructor(init?: Partial<SearchResultItem>) {
    this.work = init.work ? new Work(init.work) : null;
    this.author = init.author ? new Author(init.author) : null;

    this.fragments = init.fragments?.map(value => new SearchResultFragment(value));

  }
}

export class SearchResultFragment {


  objectName: string;
  propertyName: string;
  value: string;

  constructor(init?: Partial<SearchResultFragment>) {
    Object.assign(this, init);
  }

  getColumnName() {

    switch (this.objectName) {
      case 'Domain.Models.AuthorsNew.Author':
        return this.authorObject();

      case 'Domain.Models.AuthorsNew.AuthorBiographicalRegister':
        return $localize`:@@AuthorBibliographicalRegisters:Biographical Registers`;
      case 'Domain.Models.AuthorsNew.AuthorCatalogWrit':
        return $localize`:@@WorkBibliographyCatalogWrit:Catalogues of Writings`;
      case 'Domain.Models.AuthorsNew.AuthorLiterature':
        return $localize`:@@WorkBibliographyLiterature:Literature`;

      case 'Domain.Models.AuthorsNew.AuthorCommentary':
        return this.authorCommentaryObject();
      case 'Domain.Models.AuthorsNew.AuthorsRecordHist':
        return this.authorRecordHistObject();
      case 'Domain.Models.ExternalLinks.ExternalLink':
        return this.externalLinkObject();
      case 'Domain.Models.Persons.Person':
        return this.personObject();
      case 'Domain.Models.Places.Place':
        return this.placeObject();
      case 'Domain.Models.WorksNew.WorkEdition':
        return $localize`:@@WorkBibliographyEdition:Edition`;
      case 'Domain.Models.WorksNew.WorkLiterature':
        return $localize`:@@WorkBibliographyLiterature:Literature`;
      case 'Domain.Models.WorksNew.WorkBibliography':
        return $localize`:@@WorkBibliographyRepertorium:Catalogues`;
      case 'Domain.Models.WorksNew.Work':
        return this.workObject();
      case 'Domain.Models.WorksNew.WorkAuthor':
        return this.workAuthorObject();
      case 'Domain.Models.WorksNew.WorkCommentary':
        return this.workCommentaryObject();
      case 'Domain.Models.WorksNew.WorkLanguage':
        return this.workLanguageObject();
      case 'Domain.Models.WorksNew.WorkObjectIdentifier':
        return this.workObjectIdentifierObject();
      case 'Domain.Models.WorksNew.WorkRecordHist':
        return this.workRecordHistObject();
      case 'Domain.Models.WorksNew.WorkTransmission':
        return this.workTransmissionObject();
      case 'Domain.Models.WorksNew.WorkOrigPlace':
        return this.workOrigPlace();
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }


  private authorObject() {

    switch (this.propertyName) {
      case 'BiblNote':
        return $localize`:@@SectionNote:Note`;
      case 'Birth':
        return $localize`:@@Birth:Date of birth`;
      case 'BirthFrom':
        return $localize`:@@BirthFrom:From`;
      case 'BirthPlace':
        return $localize`:@@BirthPlace:Place of birth`;
      case 'BirthTo':
        return $localize`:@@BirthTo:To`;

      case 'Id':
        return $localize`:@@id:Id`;
      case 'Language':
        return $localize`:@@Language:Language`;
      case 'LinksNote':
        return $localize`:@@SectionNote:Note`;

      case 'Name':
        return $localize`:@@Name:Name`;
      case 'Resume':
        return $localize`:@@resume:Resume`;

      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private authorCommentaryObject() {

    switch (this.propertyName) {
      case 'Biography':
        return $localize`:@@Biography:Biography`;
      case 'CommentaryNote':
        return $localize`:@@SectionNote:Note`;
      case 'LiteraryActivity':
        return $localize`:@@LiteraryActivity:Literary Activity`;
      case 'ResearchHistory':
        return $localize`:@@Researchhistory:Research history`;
      default:
        return '';
    }
  }

  private authorRecordHistObject() {

    switch (this.propertyName) {
      case 'Commentary':
        return $localize`:@@AuthorOfComment:Author of the comment`;
      case 'Encoding':
        return $localize`:@@AuthorStmtAltName:Alternative`;
      case 'RecordHistNote':
        return $localize`:@@SectionNote:Note`;
      case 'Source':
        return $localize`:@@AuthorRecordHistorySource:Source`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private externalLinkObject() {

    switch (this.propertyName) {
      case 'Link':
        return $localize`:@@Link:Link`;
      case 'Value':
        return $localize`:@@Link:Link`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private personObject() {

    switch (this.propertyName) {
      case 'Name':
        return $localize`:@@Person:Person`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private placeObject() {

    switch (this.propertyName) {
      case 'Name':
        return $localize`:@@Place:Place`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }


  private workObject() {

    switch (this.propertyName) {
      case 'Authority':
        return $localize`:@@authority:Authority`;
      case 'BiblNote':
        return $localize`:@@SectionNote:Note`;
      case 'BriefDescription':
        return $localize`:@@briefDescription:Brief description`;
      case 'Edition':
        return $localize`:@@WorkBibliographyEdition:Edition`;
      case 'Explicit':
        return $localize`:@@explicit:Explicit`;
      case 'HeaderNote':
        return $localize`:@@SectionNote:Note`;
      case 'Incipit':
        return $localize`:@@incipit:Incipit`;

      case 'Language':
        return $localize`:@@WorkHeaderTextLang:Language`;
      case 'LinksNote':
        return $localize`:@@SectionNote:Note`;
      case 'Literature':
        return $localize`:@@WorkBibliographyLiterature:Literature`;
      case 'OrigDate':
        return $localize`:@@origDate:Date of origin`;
      case 'OrigDateFrom':
        return $localize`:@@origDateFrom:From`;
      case 'OrigDateTo':
        return $localize`:@@origDateTo:To`;
      case 'OrigPlace':
        return $localize`:@@OrigPlace:Place of origin`;
      case 'Repertorium':
        return $localize`:@@WorkBibliographyRepertorium:Catalogues`;
      case 'Title':
        return $localize`:@@title:Title`;
      case 'TransmissionNote':
        return $localize`:@@SectionNote:Note`;
      default:
        console.log(this.propertyName);
        return $localize`:@@Unknown:Unknown`;
    }
  }


  private workAuthorObject() {
    switch (this.propertyName) {
      case 'Name':
        return $localize`:@@WorkHeaderAuthor:Author`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private workCommentaryObject() {
    switch (this.propertyName) {
      case 'CommentaryNote':
        return $localize`:@@SectionNote:Note`;
      case 'Contents':
        return $localize`:@@Contents:Contents`;
      case 'Creation':
        return $localize`:@@Creation:Context of creation`;
      case 'ProfilDesc':
        return $localize`:@@WorkProfileDescription:Description`;
      case 'ResearchHistory':
        return $localize`:@@ResearchHistory:Research history`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private workLanguageObject() {
    return $localize`:@@WorkHeaderTextLang:Language`;
  }

  private workObjectIdentifierObject() {
    return $localize`:@@WorkHeaderObjectIdentifier:Source type`;
  }


  private workRecordHistObject() {

    switch (this.propertyName) {
      case 'Commentary':
        return $localize`:@@AuthorOfComment:Author of the comment`;
      case 'Encoding':
        return $localize`:@@AuthorStmtAltName:Alternative`;
      case 'RecordHistNote':
        return $localize`:@@SectionNote:Note`;
      case 'Source':
        return $localize`:@@AuthorRecordHistorySource:Source`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }


  private workTransmissionObject() {
    switch (this.propertyName) {
      case 'AltName':
        return $localize`:@@RepositoryAltName:repository alternative name`;
      case 'AltSign':
        return $localize`:@@altSign:Old Shelf-mark`;
      case 'Availability':
        return $localize`:@@Type:Type`;

      case 'Extent':
        return $localize`:@@extent:Extent`;
      case 'ExtentLink':
        return $localize`:@@Link:Link`;
      case 'Note':
        return $localize`:@@SectionNote:Note`;
      case 'Repository':
        return $localize`:@@WorkTransmissionRepository:Repository`;
      case 'Sign':
        return $localize`:@@Sign:Shelf-mark`;
      case 'SignLink':
        return $localize`:@@Link:Link`;
      default:
        return $localize`:@@Unknown:Unknown`;
    }
  }

  private workOrigPlace() {
    return $localize`:@@Place:Place`;
  }

}

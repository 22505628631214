import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CookieAgreement} from '@models';

@Injectable({
  providedIn: 'root'
})
export class CookiesBannerService {

  public cookiesAgree: BehaviorSubject<CookieAgreement> = new BehaviorSubject<CookieAgreement>(null);

  constructor() {

    const cookieAgree = JSON.parse(localStorage.getItem('CookieAgree'));
    if (cookieAgree != null) {
      this.cookiesAgree.next(cookieAgree);
    }

  }

  public allow(cookieAgreement: CookieAgreement = null) {

    if (cookieAgreement == null) {
      cookieAgreement = new CookieAgreement(true);
    }
    localStorage.setItem('CookieAgree', JSON.stringify(cookieAgreement));

    this.cookiesAgree.next(cookieAgreement);
  }

}

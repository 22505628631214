export class AuthorRecordHist {
  sources: string[];
  encoding: AuthorRecordHistUser;
  contentUpdates: AuthorRecordHistUser[];
  commentary: AuthorRecordHistUser;
  note: string;

  constructor(init?: Partial<AuthorRecordHist>) {
    Object.assign(this, init);
  }


}


export class AuthorRecordHistUser {
  name: string;
  lastName: string;
  dateTime: string;
}

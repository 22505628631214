import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSortModule} from '@angular/material/sort';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './spinner/spinner.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ErrorComponent} from './error/error.component';
import {RouterModule} from '@angular/router';
import {MyPaginatorIntl} from './paginator-intl';
import {CommonModule} from '@angular/common';
import {CookiesBannerComponent} from './cookies-banneer/cookies-banner.component';
import {CookiesSettingDialogComponent} from './cookies-banneer/cookies-setting-dialog/cookies-setting-dialog.component';
import {SortComponent} from './sort/sort.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {DetailHtmlComponent} from './detail-html/detail-html.component';
import {DetailTooltipComponent} from '@shared/detail-html/detail-tooltip/detail-tooltip.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SnackbarComponent} from '@shared/snackbar/snackbar.component';


@NgModule({
  declarations: [
    SpinnerComponent,
    NotFoundComponent,
    ErrorComponent,
    CookiesBannerComponent,
    CookiesSettingDialogComponent,
    SortComponent,
    ConfirmDialogComponent,
    SnackbarComponent,
    DetailTooltipComponent,
    DetailHtmlComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    RouterModule,
    MatDialogModule,
  ],
  exports: [
    SpinnerComponent,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatSnackBarModule,
    NotFoundComponent,
    ErrorComponent,
    MatDialogModule,
    CookiesBannerComponent,
    SortComponent,
    MatTooltipModule,
    SnackbarComponent,
    DetailHtmlComponent,
  ],
  providers: [
    {provide: MatPaginatorIntl, useValue: MyPaginatorIntl()},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ]
})
export class SharedModule {
}
